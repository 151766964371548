#visualiser {
  margin: auto;
  width: 90%;
  height: 90%;
}

#nodeInfo {
  color: darkgrey;
  font-size: 1.3vw;
}
.row {
  width: 100%;
  text-align: center;
  display: block;
}

.column {
  height: 100%;
  display: inline-block;
  background: none;
}

#grid {
  width: 100%;
  height: 80%;
}

#legend {
  width: 100%;
  display: inline-block;
}

.displayNode {
  width: 2vw;
  height: 2vw;
  display: inline-block;
}

.legendInfo {
  display: inline;
  font-size: 1.3vw;
  color: rgb(46, 44, 72);
}

dd {
  display: inline-block;
}

.options {
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5vw 1vw;
  display: inline-block;
  font-size: 1vw;
  border-radius: 10vw;
  transition: all 0.2s;
  margin: 0.3vw;
  background-color: rgb(46, 44, 72);
  color: darkgrey;
}

.options:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-decoration: underline;
  cursor: pointer;
}

.options:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

#githubLogo {
  bottom: 0;
  left: 50%;
  margin-bottom: 0.2vw;
  margin-top: 10vh;
}

@media only screen and (max-width: 600px) {
  .legendInfo {
    font-size: 3vw;
  }
  .displayNode {
    display: flex;
  }

  .options {
    padding: 1vw 2vw;
    font-size: 3vw;
  }

  #githubLogo {
    display: none;
  }
}
