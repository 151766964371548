#content {
  margin-left: 5vw;
  margin-right: 5vw;
  background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
  padding-bottom: 5%;
  margin-bottom: 3vh;
}

p {
  margin-bottom: 0;
  margin-top: 0;
  color: darkgrey;
  font-size: small;
}

h2,
h3 {
  margin-bottom: 0;
}

h2 {
  font-size: large;
}

h3 {
  font-size: medium;
  text-decoration: underline;
  color: rgb(46, 44, 72);
}
