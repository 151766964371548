nav,
html,
body,
#root {
  height: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

body {
  background: rgb(76, 104, 122);
}

#header {
  background: rgb(46, 44, 72);
  padding: 1vw;
}

.headerLink {
  padding: 1vw;
  height: 100%;
  text-align: center;
  color: darkgrey;
  font-size: 1vw;
  display: inline;
  justify-content: space-between;
  word-spacing: 10vw;
  transition: background-color 0.2s ease;
}

.headerLink:link {
  text-decoration: none;
}

.headerLink:active {
  text-decoration: underline;
}

.headerLink:hover {
  background-color: rgb(0, 0, 0);
  text-decoration: underline;
  transition: background-color 0.2 ease;
}

@media only screen and (max-width: 600px) {
  .headerLink {
    font-size: 4vw;
  }
}
